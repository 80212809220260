import React from 'react';
import Section from '../components/Section';
import Metadata from '../components/Metadata';

function Links() {
  return (
    <>
      <Metadata title='Links' />
      <Section heading='Links' backgroundColor='lightRed'></Section>
    </>
  );
}

export default Links;
